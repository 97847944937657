<template>
  <v-card>
    <v-card-title>サインイン</v-card-title>
    <v-card-text>
      <sign-in @success="onSuccess" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import { adminGetAdmin } from '@/services/functions'
import SignIn from '@/components/SignIn'

const COMPANIES = ['jobterrace']

export default {
  components: {
    SignIn
  },
  methods: {
    ...mapMutations(['setUId', 'setAdmin']),
    async onSuccess (data) {
      const { company } = data
      if (COMPANIES.includes(company)) {
        const { uid, email } = data
        const { data: admin } = await adminGetAdmin(email)
        if (admin) {
          this.setUId(uid)
          this.setAdmin(admin)
          this.$router.replace('/users')
        } else {
          this.$router.replace('/invalidAdmin')
        }
      } else {
        this.$router.replace('/invalidAdmin')
      }
    },
    onFail () {
    }
  }
}
</script>
